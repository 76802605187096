import { useState, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import useGlobalState from '../../../zustand/zustand';

const getTargetScrollPosition = id => {
  const headerHeight = document.querySelector('#app-header').offsetHeight;
  const isMenuOpen = useGlobalState.getState()?.pageHeader?.isFloating;

  const elem = document.getElementById(id);
  const yOffset = isMenuOpen ? -headerHeight : -10;
  return (elem?.getBoundingClientRect()?.top || 0) + window.pageYOffset + yOffset;
};

const PageScroll = ({ data, scrollPosition }) => {
  const [rendered, setRendered] = useState(false);
  const shouldPollCook = useGlobalState(state => state.generalSettings.shouldPollCook);
  const prevDataRef = useRef(null);

  useEffect(() => {
    // Disable scroll to top if shouldPollCook is true since that causes the page to scroll to top
    // when a background cook fetch has been done.
    if (typeof window !== 'undefined' && !shouldPollCook) {
      const hashPos = window.location.hash
        ? getTargetScrollPosition(window.location.hash.slice(1))
        : undefined;

      if (rendered) {
        setTimeout(() => {
          const { resolution, context } = data ?? {};
          if (prevDataRef.current !== null && !isEqual({ resolution, context }, prevDataRef.current)) {
            window.scrollTo({ top: hashPos ?? scrollPosition, behavior: 'smooth' });
          }
          prevDataRef.current = { resolution, context };
        }, 50);
      } else {
        const { resolution, context } = data ?? {};
        const lastPos = window?.history?.state?.state?.scrollPosition;
        if (hashPos || lastPos) {
          window.scrollTo({ top: hashPos ?? lastPos });
        }
        prevDataRef.current = { resolution, context };
      }
    }
    setRendered(true);
  }, [data, shouldPollCook]);
  return null;
};

export default PageScroll;
