/* eslint-disable css-modules/no-undef-class */
import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import history from 'utils/history';
import ButtonPrimaryAlt from 'components/atoms/buttons/ButtonPrimaryAlt';
import Typography from 'components/atoms/Typography';
import BackgroundPolygon from 'components/atoms/BackgroundPolygon';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import getImages from 'utils/getImages';
import renderStorylineElement from 'utils/renderStorylineElement';
import DarkMode from 'components/templates/DarkMode';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faBuildings } from '@fortawesome/pro-light-svg-icons';
import VCard from 'components/molecules/VCard';
import LatestArticle from 'components/molecules/LatestArticle';
import HighlightedArticle from 'components/molecules/HighlightedArticle';
import FocusAreaList from 'components/organisms/FocusAreaList';
import PuffDoubleColumn from 'components/molecules/PuffDoubleColumn';
import Link from 'containers/atoms/LinkContainer';
import boostrapGrid from 'style/bootstrap-grid.css';
import getBootstrapColumns from 'selectors/getBootstrapColumns';
import generateSizes from 'utils/generateSizes';
import pickFrom from 'utils/pickFrom';
import exists from 'utils/exists';
import { useSelector } from 'react-redux';
import QuoteBox from '../../molecules/QuoteBox/QuoteBox';
import Translate from '../../../containers/atoms/TranslateContainer';
import useTranslation from '../../../utils/hooks/useTranslation';
import SocialBall from '../../atoms/SocialBall/SocialBall';
import EventsWebinars from '../../organisms/EventsWebinars/EventsWebinars';
import { getMetadata } from '../FlexibleSection/duck/selectors';
import OGShareTags from '../../molecules/OGShareTags/OGShareTags';
import Carousel from '../../organisms/Carousel/Carousel';
import s from './RegionOfficePage.css';

const getImage = item => ({
  image: item?.teaserImage?.[0] ?? item?.coverImage?.[0],
  format: item?.teaserImage?.[0] ? 'BASE_LANDSCAPE' : 'BASE_FREEFORM',
  mobileFormat: item?.teaserImage[0] ? 'SQUARE' : 'FREEFORM',
});

const renderArticle = (article, small = false) => {
  const imageHref = pickFrom(article, 'images.images.BASE.LANDSCAPE.href_full');
  const imageWidth =
    article?.images?.images?.BASE?.LANDSCAPE?.sourceWidth || article?.images?.images?.BASE?.LANDSCAPE?.width;
  const imageHeight =
    article?.images?.images?.BASE?.LANDSCAPE?.sourceHeight ||
    article?.images?.images?.BASE?.LANDSCAPE?.height;

  const sizes = getImages('LANDSCAPE');
  const imageSizes = small
    ? generateSizes({ xl: '412px', md: '31vw', sm: 'calc(50vw - 68px)', default: '100vw' })
    : generateSizes({ xl: '848px', md: '68vw', sm: 'calc(100vw - 112px)', default: '100vw' });
  const eventType = article?.fields?.eventType === undefined ? '' : article?.fields?.eventType;
  const event = article.contentType === 'event' ? eventType : article.contentType;
  return (
    <HighlightedArticle
      key={article.href}
      href={article.href}
      imageSrc={imageHref && (small ? sizes.toSrc(imageHref, 'M') : sizes.toSrc(imageHref, 'L'))}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      small={small}
      imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
      imageSizes={imageSizes}
      contentType={event}
      headline={article?.fields?.teaserTitle || article.title}
      text={pickFrom(article, 'fields.teaser')}
    />
  );
};

const RegionOfficePage = ({
  classes,
  groupedStorylineTree,
  pageTitle,
  regionOfficeFields,
  latestArticles,
  quoteBoxData,
  contactPersons,
  focusAreaListItems,
  firstSelectedContent,
  restSelectedContent,
  expertAreaData,
  upcomingEvents,
  quoteSlideshowData,
  expertOpinionHeadline,
  latestWebinars = [],
}) => {
  const allMembersRef = useRef(null);
  const [latestToShow, setLatestToShow] = useState(10);
  const classNames = classnames(s.regionOfficePage, classes, s.darkMode);
  const { headGroup, contentGroup } = groupedStorylineTree;
  const translation = useTranslation();
  const metadata = useSelector(getMetadata);

  return (
    <>
      <OGShareTags metadata={metadata} />
      <div className={classNames}>
        <DarkMode darkMode classes={classnames(s.topWrapper, quoteBoxData?.quote && s.gutterBottom)}>
          <div className={classnames(boostrapGrid.container)}>
            <div className={classnames(boostrapGrid.row, s.imageRelative)}>
              <div className={getBootstrapColumns('16,4', '21,1', '16,1')}>
                {headGroup && headGroup.map(el => renderStorylineElement(el, true))}
                {contentGroup?.map(el => renderStorylineElement(el, true))}
              </div>
            </div>
            <div className={classnames(boostrapGrid.row)}>
              <div className={getBootstrapColumns('16,4', '8,1', '6,1')}>
                {regionOfficeFields?.address && (
                  <div>
                    <Typography
                      variant="caption"
                      component="h3"
                      classes={s.findTo}
                      darkMode
                      style={{ marginBottom: 4 }}
                    >
                      <Translate>REGION_OFFICE_PAGE.FIND_HERE</Translate>
                    </Typography>
                    <Typography component="div" darkMode>
                      {regionOfficeFields?.address}
                    </Typography>
                    <Typography component="div" darkMode>
                      {regionOfficeFields?.boxaddress !== '' && `${regionOfficeFields?.boxaddress}, `}
                      {regionOfficeFields?.postcode} {regionOfficeFields?.city}
                    </Typography>
                    <Typography
                      variant="link"
                      component={Link}
                      darkMode
                      to={regionOfficeFields?.mapUrl}
                      target="_blank"
                    >
                      <Translate>REGION_OFFICE_PAGE.OPEN_MAP</Translate>
                    </Typography>
                  </div>
                )}
                <div>
                  {(regionOfficeFields?.email || regionOfficeFields?.phone) && (
                    <div>
                      <Typography
                        variant="caption"
                        component="h3"
                        classes={s.findTo}
                        darkMode
                        style={{ marginBottom: 4 }}
                      >
                        <Translate>REGION_OFFICE_PAGE.CONTACT_US</Translate>
                      </Typography>
                    </div>
                  )}
                  <div className={s.contactChannels}>
                    {regionOfficeFields?.phone && (
                      <div className={s.contactItemFirst}>
                        <FontAwesomeIcon icon={faMobile} className={s.phoneIcon} />
                        <Typography
                          component={Link}
                          darkMode
                          to={`tel:${regionOfficeFields?.phone.replace(/\s/g, '')}`}
                        >
                          {regionOfficeFields?.phone}
                        </Typography>
                      </div>
                    )}
                    {regionOfficeFields?.email && (
                      <div className={s.contactSecondItem}>
                        <FontAwesomeIcon icon={faEnvelope} className={s.emailIcon} />
                        <Typography
                          variant="link"
                          component={Link}
                          darkMode
                          to={`mailto:${regionOfficeFields?.email}`}
                          target="_blank"
                        >
                          <Translate>REGION_OFFICE_PAGE.SEND_EMAIL</Translate>
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
                <ButtonSecondaryGhost
                  inverted
                  classes={s.allCoworkers}
                  component="a"
                  href="#medarbetare"
                  onClick={e => {
                    if (window?.location?.pathname) {
                      e.preventDefault();
                      try {
                        const { top } = allMembersRef.current.getBoundingClientRect();
                        const { pageYOffset } = window;
                        const scroll = top + pageYOffset;
                        history.push(`${window?.location?.pathname}#medarbetare`, { scrollPosition: scroll });
                        window.scrollTo(0, scroll);
                      } catch (error) {
                        console.error(error);
                      }
                    }
                  }}
                >
                  <Translate>REGION_OFFICE_PAGE.ALL_COWORKERS</Translate>
                </ButtonSecondaryGhost>
                {regionOfficeFields?.facebookUrl && (
                  <Link to={regionOfficeFields?.facebookUrl} className={s.facebookLink} target="_blank">
                    <SocialBall image={faFacebookSquare} brand="facebook" dark height={21} width={21} />
                    <Typography component="span" darkMode className={s.linkText}>
                      <Translate>REGION_OFFICE_PAGE.FOLLOW_ON_FACEBOOK</Translate>
                    </Typography>
                  </Link>
                )}
                <Link to="/medlem" className={s.facebookLink}>
                  <SocialBall image={faBuildings} brand="facebook" dark height={21} width={21} />
                  <Typography component="span" darkMode className={s.linkText}>
                    Medlemskap i Svenskt{'\u00A0'}Näringsliv
                  </Typography>
                </Link>
              </div>
              {quoteBoxData?.quote && (
                <div className={classnames(getBootstrapColumns('20,4', '14,1', '14,2'), s.quoteBox)}>
                  <QuoteBox
                    key={pickFrom(quoteBoxData, 'images.images.BASE.LANDSCAPE.href_full')}
                    imageSrc={pickFrom(quoteBoxData, 'images.images.BASE.LANDSCAPE.href_full')}
                    imageAlt={pickFrom(quoteBoxData, 'images.altText', 'name')}
                    quote={quoteBoxData.quote}
                    name={quoteBoxData.linkText}
                    assignment={quoteBoxData.assignment}
                    profileLink={quoteBoxData.profileLink}
                    isAbsolute
                  />
                </div>
              )}
            </div>
          </div>
        </DarkMode>
        <div
          className={classnames(
            boostrapGrid.container,
            s.selectedContent,
            quoteBoxData?.quote && s.gutterTop
          )}
        >
          <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
            <div className={getBootstrapColumns('20,2', '18,3', '16,4')}>
              <div className={s.articlesWrapper}>
                {firstSelectedContent && renderArticle(firstSelectedContent)}
                <div className={classnames(boostrapGrid.row)}>
                  {restSelectedContent &&
                    restSelectedContent.map(article => (
                      <div key={article.id} className={classnames(boostrapGrid['col-sm-12'])}>
                        {renderArticle(article, true)}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {expertAreaData?.length > 0 && (
          <div className={s.expertArea}>
            <div className={classnames(boostrapGrid.container)}>
              <div className={classnames(boostrapGrid.row)}>
                <div className={getBootstrapColumns('18,3', '16,4')}>
                  <Typography variant="contentTypeDate" classes={s.caption}>
                    {exists(expertOpinionHeadline) ? expertOpinionHeadline : 'Experten tycker'}
                  </Typography>
                  <Carousel
                    items={expertAreaData.map(expert => {
                      const imageHref = pickFrom(expert, 'images.images.BASE.LANDSCAPE.href_full');
                      const sizes = getImages('SQUARE');
                      return (
                        <div key={expert.id} className={s.expertWrapper}>
                          <VCard
                            classes={s.expertCard}
                            imageWrapperClasses={s.imageWrapper}
                            imageSrc={imageHref && sizes.toSrc(imageHref, 'S')}
                            imageAlt={pickFrom(expert, 'images.altText', 'name')}
                            imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                            titleLink={expert.href}
                            name={
                              <Typography variant="contentTypeDate" classes={s.expertName}>
                                {expert.name}
                              </Typography>
                            }
                            title={<Typography variant="h2">{expert.title}</Typography>}
                          />
                        </div>
                      );
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <EventsWebinars selectedEvents={upcomingEvents} latestWebinars={latestWebinars} />
        {quoteSlideshowData?.length > 0 && (
          <>
            <BackgroundPolygon
              cold
              classes={classnames(s.polygonWrapper, !(upcomingEvents?.length > 0) && s.polygonPaddingTop)}
            />
            <div
              className={classnames(s.quoteArea, !(upcomingEvents?.length > 0) && s.quoteSlideshowPaddingTop)}
            >
              <div className={classnames(boostrapGrid.container)}>
                <div className={classnames(boostrapGrid.row)}>
                  <div className={getBootstrapColumns('18,3', '20,2', '16,4')}>
                    <div className={s.quoteBoxWrapper}>
                      <Typography variant="h2" className={s.quoteBoxHeadline}>
                        <Translate>REGION_OFFICE_PAGE.THEREFORE_THE_SN</Translate>
                      </Typography>
                      {quoteSlideshowData && (
                        <Carousel
                          styleInner={{ paddingBottom: '4rem' }}
                          items={quoteSlideshowData.map(quote => {
                            const imageHref = pickFrom(quote, 'images.images.BASE.LANDSCAPE.href_full');
                            const target = quote.internalUrl ? '_self' : '_blank';
                            return (
                              <QuoteBox
                                imageSrc={imageHref && imageHref}
                                imageAlt={pickFrom(quote, 'images.altText', 'name')}
                                quote={quote.quote}
                                linkText={quote.linkText}
                                internalUrl={quote.internalUrl}
                                externalUrl={quote.externalUrl}
                                target={target}
                                inSlideshow
                                offset
                                key={quote.id}
                              />
                            );
                          })}
                        />
                      )}
                      <div className={s.readMoreMemberWrapper}>
                        <ButtonPrimaryAlt component={Link} to="/medlem/">
                          <Translate>REGION_OFFICE_PAGE.READ_MORE_MEMBERSHIP</Translate>
                        </ButtonPrimaryAlt>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {latestArticles && Array.isArray(latestArticles) && latestArticles.length > 0 && (
          <div className={classnames(boostrapGrid.container)}>
            <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
              <div
                className={classnames(
                  boostrapGrid['col-lg-16'],
                  boostrapGrid['offset-lg-4'],
                  boostrapGrid['col-md-18'],
                  boostrapGrid['offset-md-3'],
                  s.latestArticleWrapper
                )}
              >
                <h2 className={s.articleSmallHeadline}>
                  <Translate>REGION_OFFICE_PAGE.LATEST_ARTICLE_FROM</Translate> {pageTitle}
                </h2>
                {latestArticles
                  .slice(0, latestToShow)
                  .map(({ id, contentType, title, summery, date, href, images, ...rest }) => {
                    const { image, format, mobileFormat } = getImage(rest);
                    const imageHref = image?.content?.fields?.[format]?.href_full;
                    const imageAlt = image?.content?.fields?.alttext;
                    const imageWidth =
                      format === 'BASE_LANDSCAPE'
                        ? image?.content?.fields?.[format]?.sourceWidth ||
                          image?.content?.fields?.[format]?.width
                        : image?.content?.originalPictureSize?.width;
                    const imageHeight =
                      format === 'BASE_LANDSCAPE'
                        ? image?.content?.fields?.[format]?.sourceHeight ||
                          image?.content?.fields?.[format]?.height
                        : image?.content?.originalPictureSize?.height;
                    const sizes = getImages(format.slice('BASE_'.length));
                    const sizesMobile = getImages(mobileFormat);

                    return (
                      <LatestArticle
                        key={id}
                        href={href}
                        articleType={contentType}
                        headline={title}
                        day={date}
                        text={summery}
                        imageSrc={imageHref && sizes.toSrc(imageHref, 'M')}
                        imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                        imageSizes="190px"
                        imageMobileSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
                        imageMobileSrcSet={imageHref && sizesMobile.toSrcSet(imageHref)}
                        imageMobileSizes="90px"
                        imageAlt={imageAlt}
                        imageWidth={imageWidth}
                        imageHeight={imageHeight}
                      />
                    );
                  })}
                {latestArticles.length > latestToShow && (
                  <ButtonSecondaryGhost
                    key={`show-more-${latestToShow}`}
                    classes={s.showMoreEvents}
                    onClick={() => setLatestToShow(latestToShow + 10)}
                  >
                    <Translate>REGION_OFFICE_PAGE.SHOW_MORE</Translate>
                  </ButtonSecondaryGhost>
                )}
              </div>
            </div>
          </div>
        )}
        {translation.IS_SWEDISH && (
          <div className={s.puffDoubleColumnWrapper}>
            <BackgroundPolygon mediumDark classes={classnames(s.polygonAbsolute, s.top)} />
            <div className={s.puffsBg}>
              <div className={classnames(boostrapGrid.container)}>
                <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
                  <div className={classnames(s.puff, s.gutterBottom)}>
                    <div className={getBootstrapColumns('24,0', '22,1', '20,2')}>
                      <PuffDoubleColumn
                        imageSrc="/valfardsskaparna_puff.png"
                        imageAlt="Välfärdsskaparna"
                        title={<Translate>REGION_OFFICE_PAGE.VALFARDSSKAPARNA_TITLE</Translate>}
                        link="/valfardsskaparna/"
                        linkText={<Translate>REGION_OFFICE_PAGE.VALFARDSSKAPARNA_LINK_TEXT</Translate>}
                        description={<Translate>REGION_OFFICE_PAGE.VALFARDSSKAPARNA_DESCRIPTION</Translate>}
                      />
                    </div>
                  </div>
                  <div className={s.puff}>
                    <div className={getBootstrapColumns('24,0', '22,1', '20,2')}>
                      <PuffDoubleColumn
                        imageSrc="/foretagsklimat_puff.png"
                        imageAlt="Företagsklimat"
                        title={<Translate>REGION_OFFICE_PAGE.FORETAGSKLIMAT_TITLE</Translate>}
                        link="https://www.foretagsklimat.se/"
                        linkText="foretagsklimat.se"
                        description={
                          <Typography darkMode>
                            <Translate>REGION_OFFICE_PAGE.FORETAGSKLIMAT_DESCRIPTION</Translate>{' '}
                            <Typography
                              darkMode
                              variant="linkSmall"
                              component="a"
                              target="_blank"
                              href="https://www.foretagsklimat.se/"
                            >
                              www.foretagsklimat.se
                            </Typography>
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BackgroundPolygon mediumDark inverted classes={classnames(s.polygonAbsolute, s.bottom)} />
          </div>
        )}
        {contactPersons?.length > 0 && (
          <div className={s.contactWrapper}>
            <BackgroundPolygon cold className={s.backgroundPolygon} />
            <div className={s.contact}>
              <div className={classnames(boostrapGrid.container)}>
                <div className={classnames(s.rowWrapper, boostrapGrid.row)}>
                  <div
                    className={classnames(
                      boostrapGrid['col-lg-16'],
                      boostrapGrid['offset-lg-4'],
                      boostrapGrid['col-md-18'],
                      boostrapGrid['offset-md-3']
                    )}
                  >
                    <h2 className={s.smallHeadline} name="medarbetare" ref={allMembersRef}>
                      <Translate>REGION_OFFICE_PAGE.ALL_COWORKERS</Translate>
                    </h2>
                    <div>
                      <div className={classnames(boostrapGrid.row)}>
                        {contactPersons &&
                          contactPersons.map(person => {
                            const imageHref = pickFrom(person, 'images.images.BASE.LANDSCAPE.href_full');
                            const sizes = getImages('SQUARE');
                            return (
                              <div key={person.name} className={classnames(boostrapGrid['col-md-12'])}>
                                <VCard
                                  classes={s.contactCard}
                                  imageSrc={imageHref && sizes.toSrc(imageHref, 'XS')}
                                  imageAlt={pickFrom(person, 'images.altText', 'name')}
                                  imageSrcSet={imageHref && sizes.toSrcSet(imageHref)}
                                  name={person.name}
                                  title={person.title}
                                  href={person.href}
                                  linkText={<Translate>REGION_OFFICE_PAGE.SEE_CONTACT_INFORMATION</Translate>}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {focusAreaListItems?.length > 0 && (
          <div className={s.focusAreaListContainer}>
            <div className={classnames(boostrapGrid.container)}>
              <FocusAreaList listItems={focusAreaListItems} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withStyles(boostrapGrid, s)(RegionOfficePage);
