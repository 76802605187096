// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SocialBall-socialBall-1jj4o{aspect-ratio:1/1;background-color:#dee4ea;border-radius:50%;color:#62717f;display:inline-block;height:44px;position:relative;-webkit-transition:all .2s ease-in-out;transition:all .2s ease-in-out;width:44px}.SocialBall-socialBall-1jj4o.SocialBall-dark-ByMHs{background-color:#dee4ea;color:#33414e}.SocialBall-socialBall-1jj4o .SocialBall-image-1WrXv{left:50%;position:absolute;top:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.SocialBall-socialBall-1jj4o.SocialBall-colored-9EMeU{background-color:#ea8f12;color:#fff}.SocialBall-socialBall-1jj4o.SocialBall-youtube-OqNhu.SocialBall-colored-9EMeU{background-color:red}.SocialBall-socialBall-1jj4o.SocialBall-facebook-3bX3d.SocialBall-colored-9EMeU{background-color:#1877f2}.SocialBall-socialBall-1jj4o.SocialBall-twitter-3-CtC.SocialBall-colored-9EMeU{background-color:#000}.SocialBall-socialBall-1jj4o.SocialBall-linkedin-x0thZ.SocialBall-colored-9EMeU{background-color:#007bb5}.SocialBall-socialBall-1jj4o.SocialBall-instagram-j5GFm.SocialBall-colored-9EMeU{background-color:#bc2a8d}@media (min-width:541px){.SocialBall-socialBall-1jj4o{height:48px;width:48px}.SocialBall-socialBall-1jj4o:hover:not(.SocialBall-dark-ByMHs){background-color:#ea8f12;color:#fff}.SocialBall-socialBall-1jj4o.SocialBall-youtube-OqNhu:hover{background-color:red}.SocialBall-socialBall-1jj4o.SocialBall-facebook-3bX3d:hover{background-color:#1877f2}.SocialBall-socialBall-1jj4o.SocialBall-twitter-3-CtC:hover{background-color:#000}.SocialBall-socialBall-1jj4o.SocialBall-linkedin-x0thZ:hover{background-color:#007bb5}.SocialBall-socialBall-1jj4o.SocialBall-instagram-j5GFm:hover{background-color:#bc2a8d}.SocialBall-socialBall-1jj4o.SocialBall-dark-ByMHs:hover{background-color:#faf9f7}}", ""]);
// Exports
exports.locals = {
	"orange": "#ea8f12",
	"red": "#b12f26",
	"darkBlue": "#33414e",
	"steel": "#62717f",
	"lightBlue": "#eff2f5",
	"cream": "#fbfaf8",
	"socialBall": "SocialBall-socialBall-1jj4o",
	"dark": "SocialBall-dark-ByMHs",
	"image": "SocialBall-image-1WrXv",
	"colored": "SocialBall-colored-9EMeU",
	"youtube": "SocialBall-youtube-OqNhu",
	"facebook": "SocialBall-facebook-3bX3d",
	"twitter": "SocialBall-twitter-3-CtC",
	"linkedin": "SocialBall-linkedin-x0thZ",
	"instagram": "SocialBall-instagram-j5GFm"
};
module.exports = exports;
