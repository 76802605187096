import base64 from '../utils/base64';
import { calcKey } from '../utils/vfsUtils';
import { CONSTANTS } from '../components/organisms/VFSForm/vfsFormReducer';

export const VFS_SALT = 31572178;

// Genomsnittlig skattesats
export const AVERAGE_TAX = 0.40344;

async function valfardsskaparnaPlainParamsHandlerRoute(req, res, next) {
  try {
    const routePrefix =
      process.env.NODE_SERVICE_ENV === 'localhost' ? 'http://localhost:3000' : process.env.NODE_HOSTNAME_URL;

    const {
      antalAnstallda,
      totalSkatt,
      totalSalaryTKR,
      marketingName,
      legalName,
      companyName = marketingName || legalName,
    } = req.query;

    if (!companyName || !antalAnstallda || (!totalSkatt && !totalSalaryTKR)) {
      res.redirect(`${routePrefix}/valfardsskaparna`);
      return;
    }

    // NOTE! totalSalaryTKR is in thousands SEK (tkr). So "5000" means 5 miljon SEK
    const lonekostnaderPerAr = totalSkatt ? 0 : (totalSalaryTKR * 1000) / (1 - AVERAGE_TAX);
    const bolagsskattPerAr = totalSkatt ? 0 : antalAnstallda * CONSTANTS.AVG_BOLAGSSKATT_PER_ANSTALLD;

    const sumKey = base64.encode(calcKey({ companyName, antalAnstallda, salt: VFS_SALT }));
    const formula = JSON.stringify({
      companyName,
      antalAnstallda,
      lonekostnaderPerAr,
      bolagsskattPerAr,
      totalSkatt,
    });
    const data = base64.encode(formula);
    const route = `${routePrefix}/valfardsskaparna/resultat-mf/?vfsdata=${encodeURIComponent(
      data
    )}&k=${encodeURIComponent(sumKey)}`;
    res.redirect(route);
  } catch (err) {
    next(err);
  }
}

export default valfardsskaparnaPlainParamsHandlerRoute;
