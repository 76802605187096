export const CONSTANTS = {
  AVG_BOLAGSSKATT_PER_ANSTALLD: 57636,
  AVG_LON_PER_AR: 629239,
  MAX_ANSTALLDA: 399,
  get MAX_LONEKOSTNADER_PER_AR() {
    return this.MAX_ANSTALLDA * this.AVG_LON_PER_AR;
  },
  get MAX_BOLAGSSKATT_PER_AR() {
    return this.MAX_ANSTALLDA * this.AVG_BOLAGSSKATT_PER_ANSTALLD;
  },
};

export const initialState = {
  companyName: '',
  antalAnstallda: 5,
  lonekostnaderPerAr: 5 * CONSTANTS.AVG_LON_PER_AR,
  bolagsskattPerAr: 5 * CONSTANTS.AVG_BOLAGSSKATT_PER_ANSTALLD,
  steerIndividualValues: false,
};

export const TYPES = {
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_ANTAL_ANSTALLDA: 'SET_ANTAL_ANSTALLDA',
  SET_LONEKOSNADER_PER_AR: 'SET_LONEKOSNADER_PER_AR',
  SET_BOLAGSSKATT_PER_AR: 'SET_BOLAGSSKATT_PER_AR',
  SET_STEER_INDIVIDUAL_VALUES: 'SET_STEER_INDIVIDUAL_VALUES',
};

const setCompanyName = (state, action) => {
  return {
    ...state,
    companyName: action.payload,
  };
};

const setAntalAnstallda = (state, action) => {
  if (action.payload < 1) {
    return {
      ...state,
      antalAnstallda: 1,
      bolagsskattPerAr: state.steerIndividualValues
        ? state.bolagsskattPerAr
        : Math.round(1 * CONSTANTS.AVG_BOLAGSSKATT_PER_ANSTALLD),
      lonekostnaderPerAr: state.steerIndividualValues
        ? state.lonekostnaderPerAr
        : Math.round(1 * CONSTANTS.AVG_LON_PER_AR),
    };
  }
  return {
    ...state,
    antalAnstallda: action.payload,
    bolagsskattPerAr: state.steerIndividualValues
      ? state.bolagsskattPerAr
      : Math.round(action.payload * CONSTANTS.AVG_BOLAGSSKATT_PER_ANSTALLD),
    lonekostnaderPerAr: state.steerIndividualValues
      ? state.lonekostnaderPerAr
      : Math.round(action.payload * CONSTANTS.AVG_LON_PER_AR),
  };
};

const setLonekostnaderPerAr = (state, action) => {
  if (action.payload < 0) {
    return { ...state, lonekostnaderPerAr: 0 };
  }
  return {
    ...state,
    lonekostnaderPerAr: action.payload,
  };
};

const setBolagsskattPerAr = (state, action) => {
  if (action.payload < 0) {
    return { ...state, bolagsskattPerAr: 0 };
  }

  return {
    ...state,
    bolagsskattPerAr: action.payload,
  };
};

const setSteerInvidualValues = state => {
  return {
    ...state,
    steerIndividualValues: !state.steerIndividualValues,
    lonekostnaderPerAr: !state.steerIndividualValues
      ? state.lonekostnaderPerAr
      : Math.round(state.antalAnstallda * CONSTANTS.AVG_LON_PER_AR),
    bolagsskattPerAr: !state.steerIndividualValues
      ? state.bolagsskattPerAr
      : Math.round(state.antalAnstallda * CONSTANTS.AVG_BOLAGSSKATT_PER_ANSTALLD),
  };
};

const vfsFormReducer = (state, action) => {
  switch (action.type) {
    case TYPES.SET_COMPANY_NAME:
      return setCompanyName(state, action);
    case TYPES.SET_ANTAL_ANSTALLDA:
      return setAntalAnstallda(state, action);
    case TYPES.SET_LONEKOSNADER_PER_AR:
      return setLonekostnaderPerAr(state, action);
    case TYPES.SET_BOLAGSSKATT_PER_AR:
      return setBolagsskattPerAr(state, action);
    case TYPES.SET_STEER_INDIVIDUAL_VALUES:
      return setSteerInvidualValues(state);
    default:
      return state;
  }
};

export default vfsFormReducer;
